import "./OverLayLoader.css"
const OverLayloader = () => {
  return (
    <div className="overlay">
    <div className="overlay__inner">
      <div className="overlay__content">
        <span className="spinner" />
      </div>
    </div>
  </div>
  
  )
}

export default OverLayloader